<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
  
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import Vue from 'vue'
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  created(){

    if(window.location.host == 'klizer.zircly.com' || window.location.host == 'dckap.zircly.com' || window.location.host == 'dckap.dckap.co' || window.location.host == 'localhost:8080' ){
      // console.log(thi);
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        
        if(element.title == 'Help'){
          var titleFound = 0;
          console.log(element);
          for (let ind = 0; ind < element.length; ind++) {
            const child = element[ind];
            
            if(child.title == 'IT Support' ){
              titleFound = 1;
            }            
          }
          if(titleFound == 0){
            var token = Vue.$cookies.get('accessToken');
            if(window.location.host != 'klizer.zircly.com'){
              var IT_LINK = 'https://tickets.dckap.co/login/checkzircly?ac_token='+token;
            }else{
              var IT_LINK = 'https://tickets.klizer.com/login/checkzircly?ac_token='+token;
            }
            var newElement = {
              gate : "Public",
              href : IT_LINK,
              title : "IT Support",
            }
            this.items[index].children.push(newElement);
          }          
        }        
      }
    }
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
}
</script>
