import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import { bus } from "@/main.js";

export default function useVerticalNavMenu(props) {
  // ------------------------------------------------
  // isVerticalMenuCollapsed
  // ------------------------------------------------
  const isVerticalMenuCollapsed = computed({
    get: () => store.state.verticalMenu.isVerticalMenuCollapsed,
    set: val => {
      store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', val)
    },
  })

  // ------------------------------------------------
  // collapseTogglerIcon
  // ------------------------------------------------
  const collapseTogglerIcon = computed(() => {
    
    if (props.isVerticalMenuActive) {

      bus.$on('dataEvent', (data) => {
        isVerticalMenuCollapsed.value = data
      });

      isVerticalMenuCollapsed.value = JSON.parse(localStorage.getItem('is-collapsed'))
      return isVerticalMenuCollapsed.value  ? 'pinned' : 'unpinned'
    }
        
        
    return 'close'
  })

  const isMouseHovered = ref(false)

  const updateMouseHovered = val => {
    isMouseHovered.value = val
  }

  const toggleCollapsed = () => {
    isVerticalMenuCollapsed.value = !isVerticalMenuCollapsed.value;
   
  }

  return {
    isMouseHovered,
    isVerticalMenuCollapsed,
    collapseTogglerIcon,
    toggleCollapsed,
    updateMouseHovered,
  }
}
